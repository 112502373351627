import * as Sentry from '@sentry/nuxt'
import { useRuntimeConfig } from '#imports'

const config = useRuntimeConfig()
const dsn = config.public.sentry?.dsn

if (dsn) {
    Sentry.init({
        dsn,
        tracesSampleRate: 1.0,
    })
}
